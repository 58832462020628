import React from "react"
import Components from "src/storyblok/Components"
import Menu from "src/storyblok/GlobalModules/Menu"

class StoryblokEntry extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (state.story && state.story.uuid === props.pageContext.story.uuid) {
      return null
    }
    return StoryblokEntry.prepareStory(props)
  }

  static prepareStory(props) {
    const story = props.pageContext.story
    return { story }
  }

  constructor(props) {
    super(props)
    this.state = StoryblokEntry.prepareStory(props)
  }

  render() {
    let story = this.state.story ? this.state.story : null
    let content = story && story.content ? story.content : null

    return (
      <React.Fragment>
        <Menu />
        {content ? (
          React.createElement(Components(content.component), {
            storyID: story.uuid,
            name: story.name,
            slug: story.slug,
            isStartPage: story.slug === "home",
            isRootPage: story.is_startpage,
            full_slug: story.full_slug,
            date: story.created_at,
            blok: story.content,
          })
        ) : (
          <div>No components found :(</div>
        )}
      </React.Fragment>
    )
  }
}

export default StoryblokEntry
